import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  selectorWrapper: FelaCSS;
  input: FelaCSS;
}

const styles: IStyles = {
  selectorWrapper: {
    height: 36,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export default styles;
