import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { getTestVariant } from 'lib/ab-tests/selectors';
import { IApplicationState } from 'lib/types';

const testId = 'LIVE-21148_SignupRedesign';

/**
 * Returns the active variant of the test for use in selectors, components etc.
 * If the test is not activated, it returns 'control'
 */
export const getVariant_signupRedesign = (state: IApplicationState) =>
  getTestVariant(state, testId);

/**
 * Hook for initializing the test
 */
export const useABTest_signupRedesign = (isCordova: boolean) => {
  useABTestIntegration({ testId, triggerCondition: !isCordova, triggerForLoggedOutUsers: true });
};
