import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Box } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import SignupMobileWelcomeComponent from 'components/auth/signup-mobile-welcome-component/signup-mobile-welcome-component';
import SignUpComponentVariant1 from 'components/auth/signup-wrap/sign-up-component-variant1';
import componentStyles from 'components/auth/signup-wrap/signup-and-login-wrap.style';
import {
  getVariant_signupRedesign,
  useABTest_signupRedesign,
} from 'lib/ab-tests/tests/LIVE-21148_SignupRedesign';
import { getIsMobile } from 'lib/app/selectors';
import { onAuthFormFieldChange } from 'lib/auth/actions';
import { ICredentialsFields } from 'lib/auth/types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import { isCollaboratorInvite } from '../../../lib/auth/selectors';
import LoginComponent from './login-component';
import SignupComponent from './signup-component';

interface IProps {
  authData: ICredentialsFields;
  setAuthData: Dispatch<SetStateAction<ICredentialsFields>>;
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
}

const SignupAndLoginWrap = ({
  authData,
  setAuthData,
  setCollaborationAnimationVisible,
}: IProps) => {
  const router = useRouter();
  const [isSignUp, setIsSignUp] = useState(router.query.mode !== 'login');
  const isMobile = useSelector(getIsMobile);
  const isCordova = useSelector(getIsCordova);
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const [showMobileWelcome, setShowMobileWelcome] = useState(
    isCordova && router.query.welcome === 'show',
  );
  useABTest_signupRedesign(isCordova);
  const signupRedesignVariant = useSelector(getVariant_signupRedesign);
  const market = useMarket();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onAuthFormFieldChange({ target: { name: 'countryCode', value: market.country } }));
  }, [dispatch, market.country]);

  const toggle = useCallback(
    (url: string) => {
      setIsSignUp((prev) => !prev);
      router.push(url);
    },
    [router],
  );

  const redirect = useCallback(
    (signUp: boolean, url: string) => {
      setIsSignUp(signUp);
      router.push(url);
      setShowMobileWelcome(false);
    },
    [router],
  );

  const styles = componentStyles({
    isSignUp,
    isCordova,
    isMobileWelcome: showMobileWelcome,
    isCollaboratorsInvite,
  });

  return (
    <Box style={styles.signupContent} data-name="signupContent">
      {isMobile && <Box style={styles.mobileSafeArea} />}
      {showMobileWelcome ? (
        <SignupMobileWelcomeComponent redirect={redirect} />
      ) : isSignUp ? (
        signupRedesignVariant === '2' ? (
          <SignUpComponentVariant1
            setCollaborationAnimationVisible={setCollaborationAnimationVisible}
            toggleLoginIn={toggle}
            setAuthData={setAuthData}
            authData={authData}
          />
        ) : (
          <SignupComponent
            setCollaborationAnimationVisible={setCollaborationAnimationVisible}
            toggleLoginIn={toggle}
            setAuthData={setAuthData}
            authData={authData}
          />
        )
      ) : (
        <LoginComponent
          setCollaborationAnimationVisible={setCollaborationAnimationVisible}
          toggleSignUp={toggle}
          setAuthData={setAuthData}
          authData={authData}
        />
      )}
    </Box>
  );
};

export default SignupAndLoginWrap;
