import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useFela } from 'react-fela';
import { Box, ButtonV2 } from '@bridebook/ui';
import AuthCountrySelect from 'components/auth/country-select/auth-country-select';
import SignupForm from 'components/auth/signup-login-form/signup-form';
import SocialAuth from 'components/auth/social-auth/social-auth';
import WarningComponent from 'components/auth/warning-component/warning-component';
import { getIsDesktop, getIsMobile } from 'lib/app/selectors';
import { getCollaboratorsName, isCollaboratorInvite } from 'lib/auth/selectors';
import { ICredentialsFields } from 'lib/auth/types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import componentStyles from './signup-and-login-wrap.style';

interface IProps {
  authData: ICredentialsFields;
  setAuthData: Dispatch<SetStateAction<ICredentialsFields>>;
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
  toggleLoginIn: (url: string) => void;
}

const SignUpComponentVariant1 = ({
  authData,
  setAuthData,
  setCollaborationAnimationVisible,
  toggleLoginIn,
}: IProps) => {
  const { css } = useFela();
  const { t } = useTranslation('signup');
  const isCordova = useSelector(getIsCordova);
  const isMobile = useSelector(getIsMobile);
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const collaboratorsName = useSelector(getCollaboratorsName);
  const collaboratorMethod = useSelector((state) => state.auth.collaboratorInvite.method);
  const isDesktop = useSelector(getIsDesktop);

  const {
    query: { next },
  } = useRouter();

  const handleOnClick = useCallback(() => {
    const loginUrl =
      typeof next === 'string'
        ? `${UrlHelper.login}&next=${encodeURIComponent(next)}`
        : UrlHelper.login;
    toggleLoginIn(loginUrl);
  }, [next, toggleLoginIn]);

  const styles = componentStyles({
    isSignUp: false,
    isCordova,
    isMobileWelcome: false,
    showEmailAndPasswordForm: true,
  });
  return (
    <Box>
      {!isCollaboratorsInvite && isDesktop && (
        <Box style={styles.authSelectorWrapper}>
          <AuthCountrySelect />
        </Box>
      )}
      {!isCollaboratorsInvite && <Box style={styles.signupTitle}>{t('signupTitle')}</Box>}
      {!isCollaboratorsInvite && (
        <Box pt={3} style={styles.signupMethod}>
          {t('letsPlanYourWedding')}
        </Box>
      )}
      {isCollaboratorsInvite && (
        <>
          {!collaboratorMethod && (
            <Box>
              <Box style={styles.collaboratorTitle}>{t('letsPlanTogether')}</Box>
              <Box style={styles.collaboratorSubtitle}>
                {t('collaborator.welcome', {
                  partnerName1: collaboratorsName[0],
                  partnerName2: collaboratorsName[1],
                })}
              </Box>
            </Box>
          )}
          {collaboratorMethod === 'askForFeedback' && (
            <Box>
              <Box style={styles.collaboratorFeedbackTitle}>
                {t('collaborator.feedback.login.title')}
              </Box>
              <Box style={styles.collaboratorFeedbackSubtitle}>
                {t('collaborator.feedback.login.subtitle', {
                  partnerName1: collaboratorsName[0],
                  partnerName2: collaboratorsName[1],
                })}
              </Box>
            </Box>
          )}
        </>
      )}
      <Box px={3}>
        <SignupForm
          setAuthData={setAuthData}
          authData={authData}
          setCollaborationAnimationVisible={setCollaborationAnimationVisible}
        />
      </Box>
      {!isCollaboratorsInvite &&
        (!isMobile ? (
          <Box style={styles.otherMethod}>{t('otherMethod')}</Box>
        ) : (
          <Box style={styles.loginMethodsDividerWrapper}>
            <hr className={css(styles.loginMethodsDividerLineBefore)} />
            {t('loginMethod.or')}
            <hr className={css(styles.loginMethodsDividerLineAfter)} />
          </Box>
        ))}
      <Box style={styles.socialContainer}>
        <Box>
          <SocialAuth
            isSignUp
            setCollaborationAnimationVisible={setCollaborationAnimationVisible}
          />
        </Box>
        {isCollaboratorsInvite && (
          <Box style={styles.warningContainer}>
            <WarningComponent warningText={t('collaborator.login.warning')} />
          </Box>
        )}
        <ButtonV2 onClick={handleOnClick} variant="link">
          {t('alreadyHaveAnAccount')} {t('logIn')}
        </ButtonV2>
      </Box>
    </Box>
  );
};

export default SignUpComponentVariant1;
