import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { FelaCSS, colors } from '@bridebook/ui';
import { imgixBaseURL } from 'lib/utils';

interface IStyles {
  signupContainer: FelaCSS;
  desktopContainer(variant: string): FelaCSS;
  imageContainer: FelaCSS;
  image: FelaCSS;
  imageGallery: FelaCSS;
  logoContainer: FelaCSS;
  tabletHeader: FelaCSS;
  countrySelectWrapper: FelaCSS;
  backgroundImage(country: CountryCodes): FelaCSS;
  backgroundPhoneImage(country: CountryCodes): FelaCSS;
}

const styles = (): IStyles => ({
  desktopContainer: (variant) => ({
    flexDirection: 'row',
    ...(variant === '3' && {
      backgroundImage: `url(${imgixBaseURL}/assets/signup-imgs/desktop-background-image.png)`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      zIndex: 1,
    }),
  }),
  signupContainer: {
    maxWidthMdMax: {
      alignItems: 'center',
    },
    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
    },
    desktopDesign: {
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
  },
  imageContainer: {
    width: '50%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '62.5%',
    height: '100%',
    paddingBottom: 24,
  },
  imageGallery: {
    transform: 'translate(0, -35%)',
  },
  logoContainer: {
    position: 'absolute',
    top: 25,
    left: 50,
    supportsSafeAreaInset: {
      top: 'calc(25px + env(safe-area-inset-top))',
    },
  },
  tabletHeader: {
    display: 'none',
    alignItems: 'flex-start',
    width: '100%',
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderBottom: `1px solid ${colors.space15}`,
    minWidthXsMin: {
      maxWidthMdMin: {
        display: 'flex',
      },
    },
  },
  countrySelectWrapper: {
    alignSelf: 'flex-end',
    paddingTop: 4,
    paddingHorizontal: 4,
  },
  backgroundImage: (country) => ({
    height: 250,
    marginTop: '-15px',
    backgroundImage: `url(${imgixBaseURL}/assets/signup-imgs/checklist-background-${country.toLowerCase()}.png), url(${imgixBaseURL}/assets/signup-imgs/signup-background-mobile.png) `,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%, 100% 90%',
    backgroundPosition: 'left top, center bottom',
    maxWidthXxs: {
      display: 'none', // Hide on small mobile devices
    },
  }),
  backgroundPhoneImage: (country) => ({
    height: '80%',
    width: '100%',
    zIndex: 3,
    backgroundImage: `url(${imgixBaseURL}/assets/signup-imgs/desktop-phone-${country.toLowerCase()}.png)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
});

export default styles;
