import { useAppSelector } from 'cms-suppliers/lib/utils';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Box, ButtonV2 } from '@bridebook/ui';
import { IconMail } from '@bridebook/ui/src/icons';
import AuthButton from 'components/auth/auth-button/auth-button';
import AuthCountrySelect from 'components/auth/country-select/auth-country-select';
import SignupLoginForm from 'components/auth/signup-login-form/signup-login-form';
import SocialAuth from 'components/auth/social-auth/social-auth';
import WarningComponent from 'components/auth/warning-component/warning-component';
import { getVariant_signupRedesign } from 'lib/ab-tests/tests/LIVE-21148_SignupRedesign';
import { getIsDesktop, getIsMobile } from 'lib/app/selectors';
import {
  getCollaboratorsName,
  getSelectedCountryCode,
  isCollaboratorInvite,
  selectCollaboratorMethod,
} from 'lib/auth/selectors';
import { ICredentialsFields } from 'lib/auth/types';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import componentStyles from './signup-and-login-wrap.style';

interface IProps {
  authData: ICredentialsFields;
  setAuthData: Dispatch<SetStateAction<ICredentialsFields>>;
  setCollaborationAnimationVisible: Dispatch<SetStateAction<boolean>>;
  toggleLoginIn: (url: string) => void;
}

const SignupComponent = ({
  authData,
  setAuthData,
  setCollaborationAnimationVisible,
  toggleLoginIn,
}: IProps) => {
  const { t } = useTranslation('signup');
  const isCordova = useSelector(getIsCordova);
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const authForm = useSelector((state) => state.auth.form);
  const selectedCountry = useSelector(getSelectedCountryCode);
  const collaboratorsName = useSelector(getCollaboratorsName);
  const collaboratorMethod = useSelector(selectCollaboratorMethod);
  const signupRedesignVariant = useSelector(getVariant_signupRedesign);

  const [showEmailAndPasswordForm, setShowEmailAndPasswordForm] = useState(false);
  const {
    query: { next },
  } = useRouter();
  const isMobile = useSelector(getIsMobile);
  const isDesktop = useAppSelector(getIsDesktop);

  const showCountrySelect =
    !showEmailAndPasswordForm &&
    !isCollaboratorsInvite &&
    (isCordova || !isMobile) &&
    signupRedesignVariant !== '3';

  const handleOnClick = useCallback(() => {
    const loginUrl =
      typeof next === 'string'
        ? `${UrlHelper.login}&next=${encodeURIComponent(next)}`
        : UrlHelper.login;
    toggleLoginIn(loginUrl);
  }, [next, toggleLoginIn]);

  const styles = useMemo(
    () =>
      componentStyles({
        isSignUp: true,
        isCordova,
        isMobileWelcome: false,
        showEmailAndPasswordForm,
      }),
    [isCordova, showEmailAndPasswordForm],
  );

  const showOtherSignInMethods = useCallback(() => setShowEmailAndPasswordForm(false), []);

  const handleOnSetEmailAndPasswordForm = useCallback(() => {
    setShowEmailAndPasswordForm(true);
  }, []);

  return (
    <Box style={styles.contentContainer}>
      {!isCollaboratorsInvite && isDesktop && signupRedesignVariant === '3' && (
        <Box style={styles.authSelectorWrapper}>
          <AuthCountrySelect />
        </Box>
      )}
      {!isCollaboratorsInvite && (
        <>
          <Box style={styles.signupTitle}>{t('signupTitle')}</Box>
          {((isMobile && !isCordova) || (isDesktop && signupRedesignVariant === '3')) && (
            <Box pt={4} style={styles.signupMethod}>
              {signupRedesignVariant === '3' ? t('letsPlanYourWedding') : t('letsStartPlanning')}
            </Box>
          )}
        </>
      )}
      {isCollaboratorsInvite && (
        <>
          {!collaboratorMethod && (
            <Box>
              <Box style={styles.collaboratorTitle}>{t('letsPlanTogether')}</Box>
              <Box style={styles.collaboratorSubtitle}>
                {showEmailAndPasswordForm
                  ? t('collaborator.signup', {
                      partnerName1: collaboratorsName[0],
                      partnerName2: collaboratorsName[1],
                    })
                  : t('collaborator.welcome', {
                      partnerName1: collaboratorsName[0],
                      partnerName2: collaboratorsName[1],
                    })}
              </Box>
            </Box>
          )}
          {collaboratorMethod === 'askForFeedback' && (
            <Box>
              <Box style={styles.collaboratorFeedbackTitle}>
                {t('collaborator.feedback.signup.title')}
              </Box>
              <Box style={styles.collaboratorFeedbackSubtitle}>
                {t('collaborator.feedback.signup.subtitle', {
                  partnerName1: collaboratorsName[0],
                  partnerName2: collaboratorsName[1],
                })}
              </Box>
            </Box>
          )}
        </>
      )}
      {showCountrySelect && (
        <>
          <Box px={4} py={8}>
            <AuthCountrySelect />
          </Box>
          <Box style={styles.signupMethod}>{t('letsStartPlanning')}</Box>
        </>
      )}
      {showEmailAndPasswordForm && (
        <>
          <Box px={3}>
            <SignupLoginForm
              setAuthData={setAuthData}
              authData={authData}
              isSignUp
              setCollaborationAnimationVisible={setCollaborationAnimationVisible}
            />
          </Box>
          <ButtonV2 onClick={showOtherSignInMethods} variant="link">
            {t('otherMethod')}
          </ButtonV2>
        </>
      )}
      <Box style={styles.socialContainer} pt={5}>
        {!showEmailAndPasswordForm && (
          <Box style={styles.signupButtonsContainer}>
            <Box px={6}>
              <AuthButton
                disabled={!selectedCountry || authForm.disabled}
                loading={authForm.disabled}
                dataTest="continue-with-email"
                size={'large'}
                text={t('signupWithEmail')}
                icon={<IconMail color="white" width={18} />}
                onClick={handleOnSetEmailAndPasswordForm}
              />
            </Box>
            <SocialAuth
              isSignUp
              setCollaborationAnimationVisible={setCollaborationAnimationVisible}
            />
          </Box>
        )}
        {isCollaboratorsInvite && (
          <Box style={styles.warningContainer}>
            <WarningComponent
              warningText={
                showEmailAndPasswordForm
                  ? t('collaborator.signup.warning')
                  : t('collaborator.welcome.warning')
              }
            />
          </Box>
        )}
        <ButtonV2 variant="link" onClick={handleOnClick}>
          {t('alreadyHaveAnAccount')} {t('logIn')}
        </ButtonV2>
      </Box>
    </Box>
  );
};

export default SignupComponent;
