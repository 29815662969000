import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  socialButtonContainer: FelaCSS;
}

const styles: IStyles = {
  socialButtonContainer: {
    justifyContent: 'center',
    gap: 16,
    paddingHorizontal: 6,
    flexGrow: 1,
  },
};

export default styles;
