import React, { ReactNode } from 'react';
import { Box, ButtonV2, TButtonV2Size } from '@bridebook/ui';
import { TButtonVariant } from '@bridebook/ui/src/components/bbcommon/button/button';
import styles from './auth-button.style';

interface IProps {
  onClick: () => void;
  text: string;
  icon: ReactNode;
  variant?: TButtonVariant;
  size?: TButtonV2Size;
  disabled?: boolean;
  dataTest?: string;
  loading?: boolean;
}

const AuthButton = ({
  onClick,
  text,
  icon,
  variant = 'primary',
  size = 'large',
  disabled,
  dataTest,
  loading,
}: IProps) => {
  return (
    <ButtonV2
      size={size}
      variant={variant}
      onClick={onClick}
      styleButton={styles.socialAuthButtonContainer}
      styleWrapper={styles.socialAuthButtonWrapper}
      disabled={disabled}
      loading={loading}
      dataTest={dataTest}>
      <Box style={styles.socialAuthButton}>
        <Box style={styles.socialAuthFlagWrapper}>{icon}</Box>
        <Box>{text}</Box>
      </Box>
    </ButtonV2>
  );
};

export default AuthButton;
